//
//
//
//
//
//
//
//
//

export default {
  name: "DefaultLayout",
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path.includes("en")) {
          this.$vuetify.rtl = false;
          this.isRight = false;
        } else {
          this.$vuetify.rtl = true;
          this.isRight = true;
        }
      },
      immediate: true,
    },
  },
};
