import locale770bf1f0 from '../../lang/ar.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ar","numberFormats":{"ar":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"kw":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"ar","label":"المملكة العربية السعودية","file":"ar.js","iso":"ar"},{"code":"kw","label":"الكويت","file":"kw.js","iso":"kw"}],
  defaultLocale: "ar",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/opt/build/repo/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"vsf-locale","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  currency: "USD",
  country: "ar-sa",
  currencies: [{"name":"EUR","label":"Euro"},{"name":"USD","label":"Dollar"}],
  seo: true,
  useCookie: true,
  cookieKey: "language",
  normalizedLocales: [{"code":"ar","label":"المملكة العربية السعودية","file":"ar.js","iso":"ar"},{"code":"kw","label":"الكويت","file":"kw.js","iso":"kw"}],
  localeCodes: ["ar","kw"],
  additionalMessages: [],
}

export const localeMessages = {
  'ar.js': () => Promise.resolve(locale770bf1f0),
  'kw.js': () => import('../../lang/kw.js' /* webpackChunkName: "lang-kw.js" */),
}
