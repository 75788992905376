import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from 'swiper/js/swiper.esm'

// styles
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])


// Vue.use(getAwesomeSwiper(SwiperClass))
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)