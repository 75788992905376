/* eslint-disable  */

export default {
  'welcome': 'مرحبا',
  'phoneNumber': '966555252984',
  'basicPackage': 'الباقة الأساسية',
  'growthPackage': 'باقة النمو',
  'currency': 'ريال',
  'cur': 'ر.س',
  'basicPackagePrice': '1800',
  'growthPackagePrice': '4500',
  'CreateOnlineStore': 'انشاء متجر الكتروني احترافي بنقرة زر واحدة - منصة متجرة',
  'ComparisonSaudiEcommercePlatforms': 'مقارنة بين منصات التجارة الالكترونية السعودية (متجرة، سلة، زد)',
  '260': '260',
  '2600': '2,600',
  '460': '460',
  '4600': '4,600',
  '13800': '13,800',
  '99': '99',
  '990': '990',
  '299': '299',
  '2990': '2,990',
  '180': '180',
  '1800': '1,800',
  '1000': '1,000',
  '5500': '5,500',
  '4995': '4,995',
  '2700': '2,700',
  '2300': '2,300',
  '3588': '3,588',
};