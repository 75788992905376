export const Loader = () => import('../../components/Loader/index.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const GlobalAuthSide = () => import('../../components/Global/AuthSide/index.vue' /* webpackChunkName: "components/global-auth-side" */).then(c => wrapFunctional(c.default || c))
export const GlobalAutocomplete = () => import('../../components/Global/Autocomplete/index.vue' /* webpackChunkName: "components/global-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const GlobalAccordionCard = () => import('../../components/Global/AccordionCard/index.vue' /* webpackChunkName: "components/global-accordion-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalButton = () => import('../../components/Global/Button/index.vue' /* webpackChunkName: "components/global-button" */).then(c => wrapFunctional(c.default || c))
export const GlobalFAQS = () => import('../../components/Global/FAQS/index.vue' /* webpackChunkName: "components/global-f-a-q-s" */).then(c => wrapFunctional(c.default || c))
export const GlobalInput = () => import('../../components/Global/Input/index.vue' /* webpackChunkName: "components/global-input" */).then(c => wrapFunctional(c.default || c))
export const GlobalLaunchStore = () => import('../../components/Global/LaunchStore/index.vue' /* webpackChunkName: "components/global-launch-store" */).then(c => wrapFunctional(c.default || c))
export const GlobalListing = () => import('../../components/Global/Listing/index.vue' /* webpackChunkName: "components/global-listing" */).then(c => wrapFunctional(c.default || c))
export const GlobalLogo = () => import('../../components/Global/Logo/index.vue' /* webpackChunkName: "components/global-logo" */).then(c => wrapFunctional(c.default || c))
export const GlobalPackagePrices = () => import('../../components/Global/PackagePrices/index.vue' /* webpackChunkName: "components/global-package-prices" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegister = () => import('../../components/Global/Register/index.vue' /* webpackChunkName: "components/global-register" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperCustomer = () => import('../../components/Global/SwiperCustomer/index.vue' /* webpackChunkName: "components/global-swiper-customer" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperJoinStores = () => import('../../components/Global/SwiperJoinStores/index.vue' /* webpackChunkName: "components/global-swiper-join-stores" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperMain = () => import('../../components/Global/SwiperMain/index.vue' /* webpackChunkName: "components/global-swiper-main" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperNextStep = () => import('../../components/Global/SwiperNextStep/index.vue' /* webpackChunkName: "components/global-swiper-next-step" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperOpinions = () => import('../../components/Global/SwiperOpinions/index.vue' /* webpackChunkName: "components/global-swiper-opinions" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperPagination = () => import('../../components/Global/SwiperPagination/index.vue' /* webpackChunkName: "components/global-swiper-pagination" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperStore = () => import('../../components/Global/SwiperStore/index.vue' /* webpackChunkName: "components/global-swiper-store" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperVertical = () => import('../../components/Global/SwiperVertical/index.vue' /* webpackChunkName: "components/global-swiper-vertical" */).then(c => wrapFunctional(c.default || c))
export const GlobalSwiperVerticalCircle = () => import('../../components/Global/SwiperVerticalCircle/index.vue' /* webpackChunkName: "components/global-swiper-vertical-circle" */).then(c => wrapFunctional(c.default || c))
export const GlobalWhatsapp = () => import('../../components/Global/Whatsapp/index.vue' /* webpackChunkName: "components/global-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/Layout/Footer/index.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/Layout/Header/index.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/Home/Hero/index.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeOurDealers = () => import('../../components/Home/OurDealers/index.vue' /* webpackChunkName: "components/home-our-dealers" */).then(c => wrapFunctional(c.default || c))
export const HomeStoreManagement = () => import('../../components/Home/StoreManagement/index.vue' /* webpackChunkName: "components/home-store-management" */).then(c => wrapFunctional(c.default || c))
export const HomeVariousMarketing = () => import('../../components/Home/VariousMarketing/index.vue' /* webpackChunkName: "components/home-various-marketing" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegisterStepper = () => import('../../components/Global/Register/Stepper/index.vue' /* webpackChunkName: "components/global-register-stepper" */).then(c => wrapFunctional(c.default || c))
export const GlobalRegisterTheme = () => import('../../components/Global/Register/Theme/index.vue' /* webpackChunkName: "components/global-register-theme" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderMenu = () => import('../../components/Layout/Header/Menu/index.vue' /* webpackChunkName: "components/layout-header-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
